import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SponsorForm from "../components/sponsorForm";

const SponsorPage = () => (
  <Layout>
    <SEO
      title="Sponsorship options"
      keywords={[
        "Electron",
        "newsletter",
        "apps",
        "job offers",
        "node libraries"
      ]}
    />

    <div className="p-4 pt-0 max-w-sm m-auto">
      <h2>
        Sponsorship options
      </h2>
      <p>
        The Electron newsletter has sponsored links and job postings.
        Newsletters will include at a maximum 1 sponsored link and 3 job
        postings.
      </p>

      <p>
        We strive for a high quality newsletter with news and links that are
        relevant for all our readers. The same quality standard will apply to
        sponsored links and job postings. The job posting is a good investment
        if you're hiring software engineers and are using (or are planning to
        use) Electron. The main sponsorship is highly suited for you if you're
        offering a service, training or tool for Electron developers.
      </p>

      <ul>
        <li>
          Sponsored link: <b>$10</b>
        </li>
        <li>
          Job Posting: <b>$5</b>
        </li>
      </ul>

      <h3>Availability</h3>
      <p>
        The following months are still available:
      </p>

      <ul>
        <li>June: 0 sponsored links and 3 job postings</li>
        <li>July: 1 sponsored link and 3 job postings</li>
        <li>August: 1 sponsored link and 3 job postings</li>
        <li>September: 1 sponsored link and 3 job postings</li>
        <li>
          <em>Later is also possible</em>
        </li>
      </ul>

      <h3>Sponsorship Inquiry</h3>
      <SponsorForm />
    </div>
  </Layout>
);

export default SponsorPage;
