import React from "react";

import { navigateTo } from "gatsby-link";
import serialize from "form-serialize";

const handleSubmit = e => {
  const form = e.target;

  fetch("/sponsorship", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: serialize(form)
  })
    .then(() => navigateTo(form.getAttribute("action")))
    .catch(error => alert(error));

  e.preventDefault();
};

const SponsorForm = () => (
  <form
    onSubmit={handleSubmit}
    className="w-full max-w-sm m-auto"
    name="sponsorform"
    method="POST"
    netlify-honeypot="bot-field"
    data-netlify="true"
    action="/sponsorship-inquiry-sent"
  >
    <small className="pb-4 block">* required field</small>

    <input type="hidden" name="form-name" value="sponsorform" />
    <p className="hidden">
      <label>
        Don’t fill this out if you're human: <input name="bot-field" />
      </label>
    </p>

    <label htmlFor="name">Name *</label>
    <input required id="name" name="name" type="text" />

    <label htmlFor="email">E-mail *</label>
    <input required id="email" name="email" type="email" />

    <label htmlFor="company">Company name *</label>
    <input required id="company" name="company" type="text" />

    <label htmlFor="address">Invoicing Address</label>
    <textarea
      id="address"
      name="address"
      placeholder="Company address"
      rows="5"
    />

    <label htmlFor="kind">What kind of sponsored link?</label>
    <div className="inline-block relative w-full mb-6">
      <select id="kind" name="kind">
        <option value="Sponsored Link">Sponsored link</option>
        <option value="Job Posting">Job Posting</option>
      </select>
      <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>

    <label htmlFor="month">Which month do you want to sponsor?</label>
    <div className="inline-block relative w-full mb-6">
      <select id="month" name="month">
        <option value="unspecified">No specific month</option>
        <option value="june">June</option>
        <option value="july">July</option>
        <option value="august">August</option>
        <option value="september">September</option>
        <option value="september">October</option>
        <option value="september">November</option>
        <option value="later">Later</option>
      </select>
      <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>

    <label htmlFor="link">Sponsored link URL</label>
    <input id="link" name="link" type="text" />

    <label htmlFor="remarks">Questions or remarks?</label>
    <textarea id="remarks" name="remarks" rows="5" />

    <button type="submit">Inquire</button>
  </form>
);

export default SponsorForm;
